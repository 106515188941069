<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
						</div>
					</template>

					<template v-slot:end>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="products" v-model:selection="selectedProducts" dataKey="id" :paginator="true" :rows="10"  responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Event List</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
						</div>
					</template>

					
					<Column field="name" header="Name" :sortable="true" headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Name</span>
							{{slotProps.data.event_name}}
						</template>
					</Column>
					<Column field="start_time" header="Created on"  headerStyle="width:14%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Created on</span>
							{{slotProps.data.created_on}}
						</template>
					</Column>
					
					<Column headerStyle="min-width:14rem;" header="Action">
						<template #body="slotProps">
							<!-- <Button icon="pi pi-eye" class="p-button-rounded  mr-2" @click="editProduct(slotProps.data)" /> -->
                          
					<router-link :to="{ name: 'snet', params: { name1: slotProps.data.event_name }}" name="button" tag="button" ><Button icon="pi pi-eye" class="p-button-rounded p-button-warning mr-2" /></router-link>
						
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success  mr-2" />

                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="confirmDeleteProduct(slotProps.data)" />
							
		
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{height:'80%',width: '70%'}" header="Add Event Details" :modal="true" class="p-fluid">
					<div class="field">
						<label for="event_name">Name</label>
						<InputText id="event_name" v-model.trim="product.event_name" required="true" autofocus :class="{'p-invalid': submitted && !product.event_name}" />
						<small class="p-invalid" v-if="submitted && !product.event_name">Name is required.</small>
					</div>
					

				
					<template #footer>
						<Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete <b>{{product.doc_id}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = True"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProduct(product.event_id)" />
					</template>
				</Dialog>

					<Dialog v-model:visible="deleteReminderDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to send reminder<b>{{product.doc_id}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteReminderDialog = True"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="sendEmailReminder(product.event_id)" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete the selected products?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import ProductService from '../service/ProductService';
import SrateService from "../service/SrateService";

export default {
	data() {
		return {
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteReminderDialog: false,
			deleteProductsDialog: false,
			product: {},
			selectedProducts: null,
			filters: {},
			submitted: false,
			statuses: [
				{label: 'INSTOCK', value: 'instock'},
				{label: 'LOWSTOCK', value: 'lowstock'},
				{label: 'OUTOFSTOCK', value: 'outofstock'}
			]
		}
	},
	productService: null,
	created() {
		this.productService = new ProductService();
		this.srateService = new SrateService();
		this.initFilters();
	},
	mounted() {
		this.srateService.getSrateData('events_list',"EventList").then((data) => {
                this.products = data.data
                this.loading2 = false;
                this.new_disabled=false;
            });			
	},
	methods: {
		redirectToResponses(data) {this.$router.push({ path: '/smres/'+data.event_id });},
		formatCurrency(value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			return;
		},
		openNew() {
			this.product = {};
			this.submitted = false;
			this.productDialog = true;
		},
		hideDialog() {
			this.productDialog = false;
			this.submitted = false;
		},
		sendRoster(data){
			this.srateService.sendRosterById(data.event_id).then((res)=>{
					console.log(res);
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Schedule Send!', life: 3000});
              
			}).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                });
		},
		sendEmailReminder(id){
		this.deleteReminderDialog = false;
		this.submitted = true;
		console.log(this.product);
		this.srateService.sendEmailReminder(id).then((res)=>{
					console.log(res);
            })},
		saveProduct() {
			this.submitted = true;
			console.log(this.product);
			
			if (this.product.event_name.trim()
			) {
				this.srateService.createEventList(this.product)
                                .then((res) => {
                                    console.log(res);
                                    this.srateService.getSrateData('events_list','EventList').then((data) => {
                                        this.products = data.data;
                                        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Record Saved!', life: 3000});
                                    });
                                }).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                }).finally(()=> {
                                    this.loading2 = false;
                                    this.new_disabled = false;
                                    this.product = {};
                                    this.productIdEdit=-1;
									this.productDialog = false;
									this.product = {};
                                }); 		
				
			}
		},
		editProduct(product) {
			this.product = {...product};
			this.productDialog = true;
		},
		confirmDeleteProduct(product) {
			this.product = product;
			this.deleteProductDialog = true;
		},
		confirmDeleteReminder(product) {
			this.product = product;
			this.deleteReminderDialog = true;
		},
		deleteProduct(event_id) {
			this.deleteProductDialog = false;
			this.srateService.delete(event_id).then((res) => {
			console.log(res);
            });
			// this.products = this.products.filter(val => val.id !== this.product.id);
			// this.deleteProductDialog = false;
			// this.product = {};
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Deleted!', life: 3000});
		},
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.products.length; i++) {
				if (this.products[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		},
		createId() {
			let id = '';
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
			}
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteProductsDialog = true;
		},
		deleteSelectedProducts() {
			// this.products = this.products.filter(val => !this.selectedProducts.includes(val));
			// this.deleteProductsDialog = false;
			// this.selectedProducts = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Not Implemented!', life: 3000});
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
